import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroTeam from "../components/heroTeam";
import Block from "../components/block";
import Subfooter from "../components/subfooter";
import { Link } from "gatsby";

function WorkPage({ location, data }) {
  const content = data.newsPage.frontmatter;

  return (
    <Layout location={location}>
      <SEO keywords={[`bloom`, `bloom works`]} title="Bloom Works" />
      <HeroTeam
        className={`bloom-pt-hero lg:bloom-pt-heroXL`}
        text={content.hero.heroText}
      />
      <Block className={`bloom-bg-white`}>
        <div className={`bloom-mb-5 bloom-flex`}>
          <h2
            className={`bloom-text-4xl bloom-uppercase bloom-font-bold bloom-border-b-4 bloom-border-green`}
          >
            {content.title}
          </h2>
        </div>
        <div className={`bloom-flex bloom-flex-wrap`}>
          {content.posts.post
            .sort((a, b) => {
              const aDate = new Date(a.date);
              const bDate = new Date(b.date);

              return bDate - aDate;
            })
            .map((post, idx) => {
              return (
                <li
                  className={`bloom-flex bloom-border-green bloom-w-full ${
                    idx > 0
                      ? "bloom-border-t bloom-mt-4 bloom-pt-4"
                      : "bloom-pt-4"
                  }`}
                  key={post.title}
                >
                  <div className={`bloom-flex-grow`}>
                    <Link
                      className={`bloom-text-xl bloom-font-bold hover:bloom-text-green hover:bloom-underline`}
                      to={post.linkURL}
                    >
                      {post.title}
                    </Link>
                  </div>
                  <div className={`bloom-text-xl`}>{post.date}</div>
                </li>
              );
            })}
        </div>
      </Block>
      <Subfooter></Subfooter>
    </Layout>
  );
}

export const workPageQuery = graphql`
  query newsTemplate {
    newsPage: markdownRemark(frontmatter: { templateKey: { eq: "news" } }) {
      html
      frontmatter {
        title
        hero {
          heroText
        }
        posts {
          post {
            title
            linkURL
            date
          }
        }
      }
    }
  }
`;

export default WorkPage;
